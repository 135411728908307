import { useState, useEffect, useRef } from 'react';
import { Clock, ChevronLeft, ChevronRight, ArrowLeft } from 'lucide-react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { russianTextMapping, categoryNameMapping } from '../constants/translations';
import VKStickyAd from './VKStickyAd';
import VKNativeAd from './VKNativeAd';
import Layout from './Layout';
import VKInterstitialAd from './VKInterstitialAd';

const API_URL = 'https://cpapi.exploitresource.com/station';
const DOMAIN_TAG = '64305d92ef8e434ebab283ae6747745a';
const PAGE_SIZE = 12;
const LOCAL_STORAGE_KEYS = {
 ARTICLES: 'news_articles',
 CATEGORIES: 'news_categories',
 LAST_UPDATE: 'news_last_update'
};
const UPDATE_INTERVAL = 12 * 60 * 60 * 1000;

const fetchData = async (endpoint, data) => {
 try {
   const response = await fetch(`${API_URL}/${endpoint}`, {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
     },
     body: JSON.stringify({
       languageCode: 'ru',
       domainTag: DOMAIN_TAG,
       ...data
     }),
   });
   return await response.json();
 } catch (error) {
   console.error('Error:', error);
   return { status: -1, result: [] };
 }
};
const fetchAllArticles = async () => {
  try {
    let allArticles = [];
    let currentPage = 1;
    let hasMore = true;

    while (hasMore) {
      const data = await fetchData('content_station/rec/v1', {
        oneTypeIds: [0],
        pageIndex: currentPage,
        pageSize: 500
      });

      if (data.status !== 0 || !data.result?.length) {
        break;
      }

      allArticles = [...allArticles, ...data.result];
      hasMore = data.result.length === 50;
      currentPage++;
    }

    return allArticles;
  } catch (error) {
    console.error('Error fetching all articles:', error);
    return [];
  }
};


const NewsCarousel = ({ articles, onArticleClick }) => {
 const [currentSlide, setCurrentSlide] = useState(0);
 const [isAutoPlaying, setIsAutoPlaying] = useState(true);
 const [isSwiping, setIsSwiping] = useState(false);
 const [swipeOffset, setSwipeOffset] = useState(0);
 
 const containerRef = useRef(null);
 const touchStartXRef = useRef(null);
 const minSwipeDistance = 50;

 useEffect(() => {
   if (!isAutoPlaying || articles.length <= 1) return;
   const timer = setInterval(() => {
     setCurrentSlide((prev) => (prev + 1) % articles.length);
   }, 5000);
   return () => clearInterval(timer);
 }, [isAutoPlaying, articles.length]);

 const handleTouchStart = (e) => {
   if (articles.length <= 1) return;
   setIsAutoPlaying(false);
   setIsSwiping(true);
   touchStartXRef.current = e.touches[0].clientX;
   setSwipeOffset(0);
 };

 const handleTouchMove = (e) => {
   if (!touchStartXRef.current || !isSwiping || articles.length <= 1) return;
   const currentTouch = e.touches[0].clientX;
   const diff = touchStartXRef.current - currentTouch;
   setSwipeOffset(diff);
 };

 const handleTouchEnd = () => {
   if (!isSwiping || articles.length <= 1) return;
   const offset = swipeOffset;
   if (Math.abs(offset) > minSwipeDistance) {
     if (offset > 0) {
       setCurrentSlide((prev) => (prev + 1) % articles.length);
     } else {
       setCurrentSlide((prev) => (prev - 1 + articles.length) % articles.length);
     }
   }
   setIsSwiping(false);
   setSwipeOffset(0);
   touchStartXRef.current = null;
   setIsAutoPlaying(true);
 };

 if (!articles.length) return null;

 return (

   <div className="relative w-full h-[200px] sm:h-[300px] md:h-[400px] mb-4 sm:mb-6 md:mb-8 group overflow-hidden">
     <div
       ref={containerRef}
       className="relative w-full h-full"
       onTouchStart={handleTouchStart}
       onTouchMove={handleTouchMove}
       onTouchEnd={handleTouchEnd}
       onMouseEnter={() => setIsAutoPlaying(false)}
       onMouseLeave={() => setIsAutoPlaying(true)}
     >
       <div 
         className="flex transition-transform duration-300 h-full"
         style={{
           transform: articles.length > 1 ? `translateX(${-currentSlide * 100 + (swipeOffset / containerRef.current?.offsetWidth || 0) * 100}%)` : 'none',
           width: articles.length > 1 ? `${1 * 100}%` : '100%',
         }}
       >
         {articles.map((article) => (
           <div
             key={article.taskId}
             className="relative w-full h-full flex-shrink-0"
             onClick={() => !isSwiping && onArticleClick(article)}
           >
             <img
               src={article.imgUrl || "/api/placeholder/400/200"}
               alt={article.title}
               className="w-full h-full object-cover"
               draggable="false"
             />
             <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
             <div className="absolute bottom-0 left-0 right-0 p-3 sm:p-4 md:p-6 text-white">
               <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mb-1 sm:mb-2 line-clamp-2">
                 {article.title}
               </h2>
               <p className="hidden sm:block text-xs sm:text-sm md:text-base line-clamp-2 text-gray-200">
                 {article.content}
               </p>
               <div className="flex items-center mt-1 sm:mt-2 text-gray-300">
               </div>
             </div>
           </div>
         ))}
       </div>
     </div>

     {articles.length > 1 && (
       <>
         <div className="hidden md:block">
           <button
             className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 opacity-0 group-hover:opacity-100 transition-opacity"
             onClick={() => setCurrentSlide((prev) => (prev - 1 + articles.length) % articles.length)}
           >
             <ChevronLeft className="w-6 h-6" />
           </button>
           <button
             className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 opacity-0 group-hover:opacity-100 transition-opacity"
             onClick={() => setCurrentSlide((prev) => (prev + 1) % articles.length)}
           >
             <ChevronRight className="w-6 h-6" />
           </button>
         </div>

         <div className="absolute bottom-2 sm:bottom-3 md:bottom-4 left-1/2 -translate-x-1/2 flex space-x-1 sm:space-x-2">
           {articles.map((_, index) => (
             <button
               key={index}
               className={`w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full transition-all ${
                 index === currentSlide 
                   ? 'bg-white w-3 sm:w-4' 
                   : 'bg-white/50 hover:bg-white/80'
               }`}
               onClick={() => setCurrentSlide(index)}
             />
           ))}
         </div>
       </>
     )}
   </div>
 );
};

const RecommendedArticles = ({ currentArticle, onArticleClick }) => {
 const [recommendations, setRecommendations] = useState([]);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
   const loadRecommendations = async () => {
     setLoading(true);
     const data = await fetchData('content_station/rec/v1', {
       oneTypeIds: [0],
       pageIndex: 1,
       pageSize: 50
     });

     if (data.status === 0 && data.result) {
       const filteredArticles = data.result
         .filter(article => article.taskId !== currentArticle.taskId);
       setRecommendations(filteredArticles);
     }
     setLoading(false);
   };

   loadRecommendations();
 }, [currentArticle.taskId]);

 if (loading) return (
   <div className="flex justify-center py-4">
     <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
   </div>
 );

 return (
   <div className="bg-white rounded-lg shadow-md p-6">
     <h2 className="text-2xl font-bold mb-6">{russianTextMapping['Recommended Articles']}</h2>
     <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
       {recommendations.map(article => (
         <div
           key={article.taskId}
           onClick={() => onArticleClick(article)}
           className="flex gap-4 cursor-pointer hover:bg-gray-50 rounded-lg p-4 transition-colors"
         >
           <img 
             src={article.imgUrl || "/api/placeholder/200/200"}
             alt={article.title}
             className="w-24 h-24 object-cover rounded-lg flex-shrink-0"
           />
           <div>
             <h3 className="font-semibold mb-2 line-clamp-2">{article.title}</h3>
             <div className="flex items-center text-gray-500 text-sm">
             </div>
           </div>
         </div>
       ))}
     </div>
   </div>
 );
};

const ArticleCard = ({ article, onClick }) => (
<Link 
    to={`/article/${article.taskId}`}
    className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer hover:shadow-lg transition-shadow"
    onClick={onClick}
  >
     <img
     src={article.imgUrl || "/api/placeholder/400/200"}
     alt={article.title}
     className="w-full h-48 object-cover"
   />
   <div className="p-4">
     <h2 className="text-xl font-semibold mb-2 line-clamp-2">{article.title}</h2>
     <p className="text-gray-600 text-sm mb-4 line-clamp-3">{article.content}</p>
     <div className="flex items-center text-gray-500 text-sm">
     </div>
   </div>
 </Link>
);

const Categories = ({ categories, selectedCategory, onSelectCategory }) => (
 <div className="mb-8 flex gap-2 overflow-x-auto pb-2">
    <Link 
      to="/"
      className={`px-4 py-2 rounded-full whitespace-nowrap ${
        !selectedCategory
          ? 'bg-blue-500 text-white'
          : 'bg-white text-gray-700 hover:bg-gray-100'
      }`}
      onClick={() => onSelectCategory(null)}
    >
      {categoryNameMapping['Recommend'] || 'Рекомендации'}
   </Link>
   {categories.map((category) => (
      <Link
        key={category.id}
        to={`/category/${category.id}`}
        className={`px-4 py-2 rounded-full whitespace-nowrap ${
          selectedCategory?.id === category.id
            ? 'bg-blue-500 text-white'
            : 'bg-white text-gray-700 hover:bg-gray-100'
        }`}
        onClick={() => onSelectCategory(category)}
      >
        {categoryNameMapping[category.name] || category.name}
     </Link>
   ))}
 </div>
);

const Pagination = ({ currentPage, hasMore, onPageChange }) => (
 <div className="flex items-center justify-center space-x-4 mt-8 mb-4">
   <button
     onClick={() => onPageChange(currentPage - 1)}
     disabled={currentPage === 1}
     className={`flex items-center px-4 py-2 rounded-lg ${
       currentPage === 1 
         ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
         : 'bg-blue-500 text-white hover:bg-blue-600'
     }`}
   >
     <ChevronLeft className="w-5 h-5 mr-1" />
     Previous
   </button>
   
   <span className="text-gray-600">
     Page {currentPage}
   </span>
   
   <button
     onClick={() => onPageChange(currentPage + 1)}
     disabled={!hasMore}
     className={`flex items-center px-4 py-2 rounded-lg ${
       !hasMore
         ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
         : 'bg-blue-500 text-white hover:bg-blue-600'
     }`}
   >
     Next
     <ChevronRight className="w-5 h-5 ml-1" />
   </button>
 </div>
);

const NewsWebsite = () => {
 const [categories, setCategories] = useState([]);
 const [articles, setArticles] = useState([]);
 const [selectedCategory, setSelectedCategory] = useState(null);
 const [selectedArticle, setSelectedArticle] = useState(null);
 const [loading, setLoading] = useState(true);
 const [currentPage, setCurrentPage] = useState(1);
 const [hasMore, setHasMore] = useState(true);
 const [pageCache, setPageCache] = useState({});
 const [isInitialLoad, setIsInitialLoad] = useState(true);
 const { id: routeId } = useParams();
 const navigate = useNavigate();
 const [showInterstitial, setShowInterstitial] = useState(false);

 const shouldUpdate = () => {
   const lastUpdate = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_UPDATE);
   if (!lastUpdate) return true;
   return Date.now() - parseInt(lastUpdate) > UPDATE_INTERVAL;
 };

 const loadFromStorage = () => {
   try {
     const storedArticles = localStorage.getItem(LOCAL_STORAGE_KEYS.ARTICLES);
     const storedCategories = localStorage.getItem(LOCAL_STORAGE_KEYS.CATEGORIES);
     
     if (storedArticles && storedCategories) {
       setArticles(JSON.parse(storedArticles));
       setCategories(JSON.parse(storedCategories));
       return true;
     }
   } catch (error) {
     console.error('Error loading from storage:', error);
   }
   return false;
 };

 const saveToStorage = (articlesData, categoriesData) => {
   try {
     localStorage.setItem(LOCAL_STORAGE_KEYS.ARTICLES, JSON.stringify(articlesData));
     localStorage.setItem(LOCAL_STORAGE_KEYS.CATEGORIES, JSON.stringify(categoriesData));
     localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_UPDATE, Date.now().toString());
   } catch (error) {
     console.error('Error saving to storage:', error);
   }
 };
  
 const handleLinkClick = () => {
    console.log('Link clicked - checking ad interval'); // 添加日志
    const lastAdTime = localStorage.getItem('lastAdTime');
    const now = Date.now();
    
    if (!lastAdTime || now - parseInt(lastAdTime) > 30000) {
      console.log('Showing interstitial ad'); // 添加日志
      setShowInterstitial(true);
      localStorage.setItem('lastAdTime', now.toString());
    } 
  };

 useEffect(() => {
   const initializeData = async () => {
     const loaded = loadFromStorage();
     if (!loaded || shouldUpdate()) {
       setLoading(true);
      // 获取分类
      const categoriesData = await fetchData('content_station/top_type_list/v1', {});
      
      // 获取所有文章
      const allArticles = await fetchAllArticles();

      if (categoriesData.status === 0 && allArticles.length > 0) {
        setCategories(categoriesData.result);
        setArticles(allArticles);
        saveToStorage(allArticles, categoriesData.result);
      }
       setLoading(false);
       setIsInitialLoad(false);
     } else {
       setIsInitialLoad(false);
     }
   };

   initializeData();
   handleRefresh();
   const checkInterval = setInterval(() => {
     if (shouldUpdate()) {
       initializeData();
       handleRefresh();
     }
   }, 60 * 60 * 1000);

   return () => clearInterval(checkInterval);
 }, []);

 useEffect(() => {
   if (routeId) {
     if (window.location.pathname.includes('/category/')) {
       const category = categories.find(c => c.id.toString() === routeId);
       if (category) {
         setSelectedCategory(category);
       }
     } else if (window.location.pathname.includes('/article/')) {
       loadArticleDetail({ taskId: routeId });
     }
   }
 }, [routeId, categories]);

useEffect(() => {
  if (window.location.pathname === '/') {
    setSelectedArticle(null);
    setSelectedCategory(null);
    setCurrentPage(1);
  }
}, [window.location.pathname]);

  useEffect(() => {
      // 如果是首页，不显示广告
  if (window.location.pathname === '/') {
    return;
  }
    const lastAdTime = localStorage.getItem('lastAdTime');
    const now = Date.now();
    
    if (!lastAdTime || now - parseInt(lastAdTime) > 30000) {
      setShowInterstitial(true);
      localStorage.setItem('lastAdTime', now.toString());
    }
  }, [window.location.pathname]); // 当路径变化时触发

 useEffect(() => {
   const loadArticles = async () => {
     if (!selectedCategory) {
       setHasMore(true);
       setCurrentPage(1);
       return;
     }

     setLoading(true);
     const categoryId = selectedCategory?.id ?? 0;
     const cacheKey = `${categoryId}-${currentPage}`;
     
     if (pageCache[cacheKey]) {
       setArticles(pageCache[cacheKey].articles);
       setHasMore(pageCache[cacheKey].hasMore);
       setLoading(false);
       return;
     }

     const data = await fetchData('content_station/rec/v1', {
       oneTypeIds: [categoryId],
       pageIndex: currentPage,
       pageSize: PAGE_SIZE
     });

     if (data.status === 0) {
       const newArticles = data.result || [];
       const hasMore = newArticles.length === PAGE_SIZE;

       setPageCache(prev => ({
         ...prev,
         [cacheKey]: { articles: newArticles, hasMore }
       }));

       setArticles(newArticles);
       setHasMore(hasMore);
     }
     setLoading(false);
   };

   loadArticles();
 }, [selectedCategory, currentPage]);

 const loadArticleDetail = async (article) => {
   setLoading(true);
   const data = await fetchData('content_station/detail/v1', {
     source: 'vcm',
     taskId: article.taskId
   });
   if (data.status === 0 && data.result) {
     setSelectedArticle(data.result);
     navigate(`/article/${article.taskId}`);
     window.scrollTo(0, 0);
   }
   setLoading(false);
 };


 const handleArticleLoad = async (article) => {
   setLoading(true);
   const data = await fetchData('content_station/detail/v1', {
     source: 'vcm',
     taskId: article.taskId
   });
   if (data.status === 0 && data.result) {
     window.scrollTo(0, 0);
     setSelectedArticle(data.result);
     navigate(`/article/${article.taskId}`);
   }
   setLoading(false);
 };

 const handleCategoryChange = (category) => {
   setSelectedCategory(category);
   setCurrentPage(1);
   setHasMore(true);
    if (category) {
      navigate(`/category/${category.id}`);
    } else {
      navigate('/');
    }
 };

 const handlePageChange = (page) => {
   setCurrentPage(page);
 };

 const handleRefresh = async () => {
  setLoading(true);
  try {
    const [categoriesData, articlesData] = await Promise.all([
      fetchData('content_station/top_type_list/v1', {}),
      fetchData('content_station/rec/v1', {
        oneTypeIds: [0],
        pageIndex: 1,
        pageSize: 500
      })
    ]);

    if (categoriesData.status === 0 && articlesData.status === 0) {
      setCategories(categoriesData.result);
      setArticles(articlesData.result);
      saveToStorage(articlesData.result, categoriesData.result);
    }
  } finally {
    setLoading(false);
  }
 };

 if (isInitialLoad || (loading && !articles.length)) {
   return (
     <div className="flex items-center justify-center h-screen">
       <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
     </div>
   );
 }

 return (
   <>
     <Helmet>
       <title>
         {selectedArticle 
           ? `${selectedArticle.title} - ${russianTextMapping['News Portal']}`
           : selectedCategory
             ? `${selectedCategory.name} - ${russianTextMapping['News Portal']}`
             : russianTextMapping['News Portal - Latest News']
         }
       </title>
       <meta name="description" content={
         selectedArticle 
           ? selectedArticle.content.substring(0, 160)
           : russianTextMapping['Latest news and updates from around the world']
       } />
       <link rel="canonical" href={`${window.location.origin}${window.location.pathname}`} />
     </Helmet>
     <div className="min-h-screen bg-gray-100">
       <header className="bg-white shadow sticky top-0 z-50">
         <div className="max-w-7xl mx-auto px-4 py-6 flex justify-between items-center">
            <Link 
              to="/"
              className="text-3xl font-bold text-gray-900 hover:text-blue-600 transition-colors"
              onClick={() => {
                setSelectedArticle(null);
                setSelectedCategory(null);
                setCurrentPage(1);
                setArticles([]); // 清空文章列表
                handleRefresh().then(() => {
                          navigate('/');
                          // window.location.reload(); // 添加刷新
                        });
              }}
            >
              {russianTextMapping['News Portal']}
            </Link>
            <VKStickyAd />
         </div>
       </header>

       <main className="max-w-7xl mx-auto px-4 py-6">
         {!selectedArticle ? (
           <>
             {!loading && articles.length > 0 && (
               <NewsCarousel 
                 articles={articles.slice(0, 5)} 
                 onArticleClick={loadArticleDetail}
               />
             )}

             <Categories 
               categories={categories}
               selectedCategory={selectedCategory}
               onSelectCategory={handleCategoryChange}
             />

             {loading ? (
               <div className="flex justify-center my-8">
                 <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
               </div>
             ) : articles.length > 0 ? (
               <>
                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                   {articles.map((article) => (
                     <ArticleCard
                       key={article.taskId}
                       article={article}
                        onClick={() => {
                          loadArticleDetail(article);
                        }}
                     />
                   ))}
                 </div>

                 <Pagination
                   currentPage={currentPage}
                   hasMore={hasMore}
                   onPageChange={handlePageChange}
                 />
               </>
             ) : (
               <div className="text-center py-12">
                 <p className="text-gray-500 text-lg">No articles found</p>
               </div>
             )}
           </>
         ) : (
          <div className="space-y-6">
            {!loading && articles.length > 0 && (
              <NewsCarousel 
                articles={articles.slice(0, 5)} 
                onArticleClick={loadArticleDetail}
              />
            )}

            <Categories
              categories={categories}
              selectedCategory={selectedCategory}
              onSelectCategory={(category) => {
                setSelectedArticle(null);
                handleCategoryChange(category);
              }}
            />
             <div className="bg-white rounded-lg shadow-md p-6">
               <button
                 onClick={() => {
                   setSelectedArticle(null);
                   navigate(-1);
                 }}
                 className="flex items-center text-blue-500 mb-4 hover:text-blue-600"
               >
                 <ArrowLeft className="w-4 h-4 mr-1" />
                  {russianTextMapping['Back to articles']}
               </button>
               <article>
                 <h1 className="text-3xl font-bold mb-4">{selectedArticle.title}</h1>
                 <div className="flex items-center text-gray-500 mb-6">
                 </div>
                 {selectedArticle.imgUrl && (
                   <img
                     src={selectedArticle.imgUrl}
                     alt={selectedArticle.title}
                     className="w-full max-w-3xl mx-auto mb-6 rounded-lg"
                   />
                 )}
                  <div className="prose max-w-none space-y-4">
                    <p className="text-gray-700 leading-relaxed whitespace-pre-line">
                      {selectedArticle.content.slice(0, selectedArticle.content.length / 2)}
                    </p>
                    
                    <div className="my-8">
                      <VKNativeAd />
                    </div>
                    
                    <p className="text-gray-700 leading-relaxed whitespace-pre-line">
                      {selectedArticle.content.slice(selectedArticle.content.length / 2)}
                    </p>
                  </div>
               </article>
             </div>
             
             <RecommendedArticles 
               currentArticle={selectedArticle}
               onArticleClick={loadArticleDetail}
             />
           </div>
         )}
       </main>
      <Layout title="Latest News" content="Stay updated with the latest news">

      </Layout>
     </div>
   </>
 );
};

export default NewsWebsite;