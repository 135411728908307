import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NewsWebsite from './components/NewsWebsite';
import AboutPage from './pages/AboutPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsPage from './pages/TermsPage';
import ContactPage from './pages/ContactPage';
import CookiePolicy from './pages/CookiePolicy';
import GenerateSitemap from './pages/GenerateSitemap';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NewsWebsite />} />
        <Route path="/article/:id" element={<NewsWebsite />} />
        <Route path="/category/:id" element={<NewsWebsite />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/cookies" element={<CookiePolicy />} />
        <Route path="/generate-sitemap/:key" element={<GenerateSitemap />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
