import React from 'react';
import Layout from '../components/Layout';

const PrivacyPolicy = () => (
  <Layout 
    title="Политика конфиденциальности"
    content="Our commitment to protecting your privacy and personal information."
  >
    <div className="bg-white rounded-lg shadow-md p-8 mt-8">
      <h1 className="text-3xl font-bold mb-8">Политика конфиденциальности</h1>
      <div className="space-y-6 text-gray-700">
        <section>
<h2>1. Общие положения</h2>
        <p>TradeNewsNet ("мы", "наш" или "Сайт") уважает вашу конфиденциальность и обязуется защищать ваши персональные данные. Настоящая политика конфиденциальности описывает, как мы собираем, используем и защищаем вашу информацию.</p>

        <h2>2. Собираемая информация</h2>
        <p>Мы собираем следующие типы информации:</p>
        <ul>
            <li>Информация, предоставляемая вами добровольно (имя, email)</li>
            <li>Технические данные (IP-адрес, тип браузера, устройства)</li>
            <li>Данные о взаимодействии с сайтом</li>
            <li>Файлы cookie и похожие технологии</li>
        </ul>

        <h2>3. Рекламные технологии</h2>
        <p>Мы используем рекламные технологии и сотрудничаем с рекламными партнерами для показа релевантной рекламы:</p>
        <ul>
            <li>Мы используем сторонние рекламные сети (Google AdSense, Яндекс.Директ и другие)</li>
            <li>Рекламные партнеры могут использовать cookies и похожие технологии для сбора информации о ваших интересах</li>
            <li>Собранные данные могут использоваться для показа персонализированной рекламы</li>
            <li>Вы можете отказаться от персонализированной рекламы в настройках вашего браузера или рекламных сетей</li>
        </ul>

        <h2>4. Использование информации</h2>
        <p>Мы используем собранную информацию для:</p>
        <ul>
            <li>Предоставления и улучшения наших услуг</li>
            <li>Персонализации контента и рекламы</li>
            <li>Анализа использования сайта</li>
            <li>Коммуникации с пользователями</li>
            <li>Соблюдения законодательных требований</li>
        </ul>

        <h2>5. Передача данных третьим лицам</h2>
        <p>Мы можем передавать информацию:</p>
        <ul>
            <li>Рекламным партнерам для показа релевантной рекламы</li>
            <li>Аналитическим сервисам для анализа использования сайта</li>
            <li>По требованию законодательства</li>
        </ul>

        <h2>6. Защита данных</h2>
        <p>Мы принимаем технические и организационные меры для защиты ваших данных от несанкционированного доступа, утечки или потери.</p>

        <h2>7. Ваши права</h2>
        <p>Вы имеете право:</p>
        <ul>
            <li>Получить доступ к своим данным</li>
            <li>Исправить неточные данные</li>
            <li>Удалить свои данные</li>
            <li>Отказаться от рекламных рассылок</li>
            <li>Управлять настройками файлов cookie</li>
        </ul>

        <h2>8. Изменения в политике</h2>
        <p>Мы оставляем за собой право вносить изменения в данную политику. Все изменения будут опубликованы на этой странице.</p>

        <h2>9. Контактная информация</h2>
        <p>По вопросам, связанным с обработкой персональных данных, обращайтесь:</p>
        <p>Email: info@tradenewsnet.com</p>        </section>
        {/* Add more sections */}
      </div>
    </div>
  </Layout>
);

export default PrivacyPolicy;
