import { useEffect } from 'react';

const VKStickyAd = () => {
  useEffect(() => {
    // 添加广告脚本
    const script = document.createElement('script');
    script.src = 'https://ad.mail.ru/static/ads-async.js';
    script.async = true;
    document.head.appendChild(script);

    // 添加初始化代码
    const initScript = document.createElement('script');
    initScript.text = '(MRGtag = window.MRGtag || []).push({})';
    document.body.appendChild(initScript);

    return () => {
      // 清理
      document.head.removeChild(script);
      document.body.removeChild(initScript);
    };
  }, []);

  return (
    <div className="fixed bottom-0 left-1/2 -translate-x-1/2 z-50">
      <ins 
        className="trg-b-banner floating"
        style={{ display: 'inline-block' }}
        data-ad-client="ad-1732551"
        data-ad-slot="1732551"
      />
    </div>
  );
};

export default VKStickyAd;
