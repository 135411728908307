import React from 'react';
import Layout from '../components/Layout';

const TermsPage = () => (
  <Layout
    title="Условия использования"
    content="Terms and conditions for using News Portal."
  >
    <div className="bg-white rounded-lg shadow-md p-8 mt-8">
      <h1 className="text-3xl font-bold mb-8">Условия использования</h1>
      <div className="space-y-6 text-gray-700">
        <p>Последнее обновление: 30 ноября 2024 г.</p>

        <h2>1. Общие положения</h2>
        <p>Используя веб-сайт TradeNewsNet (далее "Сайт"), вы принимаете настоящие условия использования. Если вы не согласны с данными условиями, пожалуйста, воздержитесь от использования Сайта.</p>

        <h2>2. Права интеллектуальной собственности</h2>
        <p>Весь контент, размещенный на Сайте, включая тексты, изображения, логотипы и другие материалы, защищен законами об авторском праве. Копирование, распространение или использование материалов Сайта без письменного разрешения запрещено.</p>

        <h2>3. Использование контента</h2>
        <p>Разрешается цитирование материалов Сайта с обязательным указанием активной гиперссылки на источник. Максимальный объем цитирования не должен превышать 1000 знаков.</p>

        <h2>4. Ограничение ответственности</h2>
        <p>Мы стремимся предоставлять точную и актуальную информацию, однако не гарантируем абсолютную точность, полноту или своевременность материалов. Сайт не несет ответственности за любые убытки, возникшие в результате использования или невозможности использования наших материалов.</p>

        <h2>5. Конфиденциальность</h2>
        <p>Использование Сайта регулируется нашей Политикой конфиденциальности. Используя Сайт, вы соглашаетесь с условиями обработки персональных данных, описанными в Политике конфиденциальности.</p>

        <h2>6. Изменение условий</h2>
        <p>Мы оставляем за собой право изменять данные условия использования в любое время. Продолжая использовать Сайт после внесения изменений, вы принимаете обновленные условия.</p>

        <h2>7. Применимое право</h2>
        <p>Настоящие условия использования регулируются и толкуются в соответствии с законодательством Российской Федерации.</p>

        <h2>8. Контактная информация</h2>
        <p>По вопросам, связанным с условиями использования, обращайтесь по адресу: info@tradenewsnet.com</p>
        {/* Add more sections */}
      </div>
    </div>
  </Layout>
);

export default TermsPage;
