import { useEffect } from 'react';

const VKNativeAd = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://ad.mail.ru/static/ads-async.js';
    script.async = true;
    document.head.appendChild(script);

    const initScript = document.createElement('script');
    initScript.text = '(MRGtag = window.MRGtag || []).push({})';
    document.body.appendChild(initScript);

    return () => {
      if (script.parentNode) {
        document.head.removeChild(script);
      }
      if (initScript.parentNode) {
        document.body.removeChild(initScript);
      }
    };
  }, []);

  return (
    <ins 
      className="mrg-tag"
      style={{ display: 'block', textDecoration: 'none' }}
      data-ad-client="ad-1732555"
      data-ad-slot="1732555"
    />
  );
};

export default VKNativeAd;
