import React from 'react';
import Layout from '../components/Layout';


const AboutPage = () => (
  <Layout
    title="О нас - Новостной портал"
    content="Learn more about News Portal and our mission to deliver quality news."
  >
    <div className="bg-white rounded-lg shadow-md p-8 mt-8">
      <h1 className="text-3xl font-bold mb-8">О нас - Новостной портал</h1>
      <div className="space-y-6 text-gray-700">
  <div class="section">
    <h2>Наша миссия</h2>
    <p>Мы стремимся предоставлять нашим читателям самые актуальные и достоверные новости 24 часа в сутки. Наша команда профессиональных журналистов работает круглосуточно, чтобы держать вас в курсе важнейших событий в России и мире.</p>
  </div>

  <div class="section">
    <h2>Наши принципы</h2>
    <p>Достоверность, объективность и независимость - основные принципы нашей работы. Мы тщательно проверяем все факты и предоставляем информацию без политической предвзятости.</p>
  </div>

  <div class="section">
    <h2>Наша команда</h2>
    <p>В нашу редакцию входят опытные журналисты, редакторы и аналитики с многолетним стажем работы в ведущих СМИ. Мы постоянно развиваемся и следим за последними тенденциями в медиа-индустрии.</p>
  </div>

  <div class="contact">
    <h2>Свяжитесь с нами</h2>
    <p>Электронная почта: info@tradenewsnet.com</p>
  </div>
        {/* Add more sections */}
      </div>
    </div>
  </Layout>
);

export default AboutPage;
