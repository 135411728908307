// src/components/Layout.js
import React from 'react';
import { Link } from 'react-router-dom';
import { russianTextMapping, categoryNameMapping } from '../constants/translations';
import Footer from './Footer';

const Layout = ({ children, title, content }) => (
  <>
    <header className="bg-white shadow sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 py-6 flex justify-between items-center">
        <Link 
          to="/" 
          className="text-3xl font-bold text-gray-900 hover:text-blue-600 transition-colors"
        >
          {russianTextMapping['News Portal']}
        </Link>
      </div>
    </header>

    <main className="flex-grow container mx-auto px-4 py-6">
      {children}
    </main>

    <footer className="bg-white shadow mt-auto">
      <div className="max-w-7xl mx-auto py-8 px-4">
        <div className="flex flex-col items-end space-y-4 mb-6">
          <Link to="/about" className="text-gray-500 hover:text-gray-900">
            {russianTextMapping['About']}
          </Link>
          <Link to="/contact" className="text-gray-500 hover:text-gray-900">
            {russianTextMapping['Contact']}
          </Link>
          <Link to="/terms" className="text-gray-500 hover:text-gray-900">
            {russianTextMapping['Terms of Service']}
          </Link>
          <Link to="/privacy" className="text-gray-500 hover:text-gray-900">
            {russianTextMapping['Privacy Policy']}
          </Link>
          <Link to="/cookies" className="text-gray-500 hover:text-gray-900">
            {russianTextMapping['Cookie Policy']}
          </Link>
        </div>
        <div className="text-center text-gray-500 text-sm">
          © 2024 {russianTextMapping['News Portal']}. {russianTextMapping['All rights reserved']}.
        </div>
      </div>
    </footer>
  </>
);

export default Layout;