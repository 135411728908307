import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const LOCAL_STORAGE_KEYS = {
  ARTICLES: 'news_articles',
  CATEGORIES: 'news_categories',
};

const GenerateSitemap = () => {
  const [status, setStatus] = useState('');
  const { key } = useParams();

  useEffect(() => {
    const SECRET_KEY = 'realme'; // 替换为你的密钥

    if (key !== SECRET_KEY) {
      setStatus('Invalid key');
      return;
    }

    const generateSitemap = () => {
      try {
        const DOMAIN = window.location.origin;
        
        // 静态路由
        const staticRoutes = [
          '/',
          '/about',
          '/contact',
          '/privacy',
          '/terms',
          '/cookies'
        ];

        // 从本地存储获取分类和文章数据
        const storedArticles = localStorage.getItem(LOCAL_STORAGE_KEYS.ARTICLES);
        const storedCategories = localStorage.getItem(LOCAL_STORAGE_KEYS.CATEGORIES);
        
        const articles = JSON.parse(storedArticles || '[]');
        const categories = JSON.parse(storedCategories || '[]');

        const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
${staticRoutes.map(route => `  <url>
    <loc>${DOMAIN}${route}</loc>
    <lastmod>${new Date().toISOString()}</lastmod>
    <changefreq>${route === '/' ? 'daily' : 'weekly'}</changefreq>
    <priority>${route === '/' ? '1.0' : '0.8'}</priority>
  </url>`).join('\n')}
${categories.map(category => `  <url>
    <loc>${DOMAIN}/category/${category.id}</loc>
    <changefreq>daily</changefreq>
    <priority>0.8</priority>
  </url>`).join('\n')}
${articles.map(article => `  <url>
    <loc>${DOMAIN}/article/${article.taskId}</loc>
    <lastmod>${article.dateStr}</lastmod>
    <changefreq>weekly</changefreq>
    <priority>0.6</priority>
  </url>`).join('\n')}
</urlset>`;

        // 创建 Blob 并生成下载链接
        const blob = new Blob([sitemap], { type: 'application/xml' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sitemap.xml';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        setStatus('Sitemap generated successfully. Downloading file...');
      } catch (error) {
        setStatus('Error generating sitemap: ' + error.message);
      }
    };

    generateSitemap();
  }, [key]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Sitemap Generator</h1>
      <p className="text-gray-700">{status}</p>
      <pre className="mt-4 p-4 bg-gray-100 rounded">
        当前存储的文章数: {localStorage.getItem(LOCAL_STORAGE_KEYS.ARTICLES) ? 
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.ARTICLES)).length : 0}
        {'\n'}
        当前存储的分类数: {localStorage.getItem(LOCAL_STORAGE_KEYS.CATEGORIES) ? 
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.CATEGORIES)).length : 0}
      </pre>
    </div>
  );
};

export default GenerateSitemap;
