// src/constants/translations.js
export const russianTextMapping = {
  'News Portal': 'Новостной портал',
  'Back to articles': 'Назад к статьям',
  'Recommended Articles': 'Рекомендуемые статьи',
  'About': 'О нас',
  'Privacy Policy': 'Политика конфиденциальности',
  'Terms of Service': 'Условия использования',
  'Contact': 'Контакты',
  'Cookie Policy': 'Политика использования файлов cookie',
  'All rights reserved': 'Все права защищены',
  'Refresh News': 'Обновить новости',
  'Latest News': 'Последние новости',
  'News Portal - Latest News': 'Новостной портал - Последние новости',
  'Latest news and updates from around the world': 'Последние новости и обновления со всего мира'
};

export const categoryNameMapping = {
  'Recommend': 'Рекомендации',
  'Delicacy': 'Кулинария',
  'Travel': 'Путешествия',
  'Animal': 'Животные',
  'Entertainment': 'Развлечения',
  'Technology': 'Технологии',
  'Information': 'Информация',
  'Car': 'Автомобили',
  'Life': 'Жизнь'
};