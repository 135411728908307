import React from 'react';
import Layout from '../components/Layout';

const ContactPage = () => (
  <Layout
    title="Контакты"
    content="Get in touch with News Portal team."
  >
    <div className="bg-white rounded-lg shadow-md p-8 mt-8">
      <h1 className="text-3xl font-bold mb-8">Контакты</h1>
      <div className="space-y-6 text-gray-700">
        <h1>Свяжитесь с нами</h1>
        <p>По всем вопросам, пожалуйста, обращайтесь по электронной почте:</p>
        <p class="email">info@tradenewsnet.com</p>
        <p>Мы стремимся ответить на все запросы в течение одного рабочего дня.</p>
      </div>
    </div>
  </Layout>
);

export default ContactPage;
